import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../styles.scss"

const BalkonPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="container">xxx</div>
    </Layout>
  )
}

export default BalkonPage
